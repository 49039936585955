import React, {useEffect, useRef, useState} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TopScreen from '../components/top-screen'
import MeetDiana from '../components/meet-diana'
import BookOrder from '../components/book-order'
import BookDescription from '../components/book-description'
import Awards from '../components/awards'
import Testimonials from '../components/testimonials'
import BookMemos from '../components/book-memos'
import BookOrderPopup from "../components/book-order-popup"

const IndexPage = (props) => {

  const [openPopup, setOpenPopup] = useState(false)

  const orderRef = useRef();

  const handleScroll = () => {
    orderRef.current.scrollIntoView({behaviour: 'smooth'})
  }

  useEffect(() => {
    console.log(props.location);
  }, [props]);

  return (
    <Layout setOpenPopup={setOpenPopup}>
    <SEO title="Home Page" />
    <BookOrderPopup openPopup={openPopup} setOpenPopup={setOpenPopup} />
    <TopScreen openPopup={openPopup} setOpenPopup={setOpenPopup}/>
    <MeetDiana />
    <BookOrder openPopup={openPopup} setOpenPopup={setOpenPopup} orderRef={orderRef}/>
    <BookDescription handleScroll={handleScroll}/>
    <Awards />
    <Testimonials />
    <BookMemos handleScroll={handleScroll}/>
    {/*
    <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>

      <Image />

    <Link to="/excerpt/">Go to page 2</Link> <br />
    <Link to="/using-typescript/">Go to "Using TypeScript"</Link> */}
  </Layout>
  )
}

export default IndexPage