import React, { useEffect, useState } from "react"
import Paypal from "gatsby-plugin-paypal"

const PaypalButton = () => {
  const [visible, setVisible] = useState(global.window && global.window.__dw_paypalLoaded);

  useEffect(() => {
    if (!global.window || global.window.__dw_paypalLoaded) {
      return;
    }

    const scriptEl = document.createElement('script');
    scriptEl.src = `https://www.paypal.com/sdk/js?client-id=${process.env.PAYPAL_CLIENT_ID}&currency=USD&vault=true&disable-funding=credit`;
    document.body.appendChild(scriptEl);
    scriptEl.onload = () => {
      setVisible(true);
      global.window.__dw_paypalLoaded = true;
    }
  }, []);

  return !visible ? null : <Paypal
        style={{
          shape: 'rect',
          color: 'blue',
          layout: 'horizontal',
          label: 'paypal',
        }}
        amount={5}
        currency="USD"
      />
}

export default PaypalButton